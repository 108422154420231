<template>
  <div class="image-capture" :class="this.loading ? 'loading loading-lg' : ''">
    <div
      class="device-settings"
      :class="{ 'device-settings-edit': device.edit }"
      v-show="device.items.length > 0 && showVideo"
    >
      <button
        class="btn btn-icon btn-action btn-info btn-edit"
        @click.stop="device.edit = true"
        v-if="!device.edit"
      ><fa-icon :icon="['fas', 'cog']"></fa-icon></button>
      <template v-else>
        <div class="tooltip tooltip-bottom" data-tooltip="Dispositivo de vídeo">
          <select
            class="form-select"
            v-model="device.id"
            @change="updateDevice"
          >
            <option
              v-for="device in device.items"
              :value="device.deviceId"
              :key="device.deviceId"
            >{{ device.label }}</option>
          </select>
        </div>
        <div class="tooltip tooltip-bottom" data-tooltip="Modo de qualidade">
          <select
            class="form-select"
            v-model="device.quality"
            @change="onChangeQuality"
          >
            <option value="auto">Automático</option>
            <option value="compatibility">Compatibilidade</option>
          </select>
        </div>
        <button
          class="btn btn-icon btn-action btn-info"
          @click.stop="device.edit = false"
        ><fa-icon :icon="['fal', 'check']"></fa-icon></button>
      </template>
    </div>

    <div v-if="errorMessage" :style="sizeStyles" class="has-error">
      <div class="text-center p-2">
        <fa-icon :icon="['fal', 'exclamation-triangle']"></fa-icon>
        <div class="mt-2">{{ errorMessage }}</div>
      </div>
    </div>
    <video
      v-else
      :width="width"
      :height="height"
      :class="{'no-video': !loading && !hasVideo}"
      ref="video"
      @click="snapshot"
      v-show="showVideo"
    ></video>

    <div :style="previewStyles" class="capture-preview" v-show="dataURL" @click="clear"></div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import 'canvas-toBlob';
import 'image-capture';
import localforage from 'localforage';
import {
  listDevices,
  getSupportedConstraints,
  getUserMedia,
} from '@/helpers/media';
// https://developer.chrome.com/blog/imagecapture/
// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

const STORAGE_KEY = 'video-capture-quality';

export default {
  props: {
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 300,
    },
    imageWidth: {
      type: Number,
      default: 800,
    },
    imageHeight: {
      type: Number,
      default: 600,
    },
    continuous: {
      type: Boolean,
      default: false,
    },
    contentType: {
      type: String,
      default: 'image/jpeg',
    },
  },
  data() {
    return {
      mediaStream: null,
      imageCapture: null,
      video: null,
      canvas: null,
      context: null,
      dataURL: null,
      loading: false,
      device: {
        id: null,
        quality: 'auto',
        items: [],
        edit: false,
      },
      errorMessage: null,
    };
  },
  async mounted() {
    try {
      await this.init();
    } catch (e) {
      this.errorMessage = e.message;
    }
  },
  computed: {
    hasVideo() {
      return this.mediaStream !== null;
    },
    showVideo() {
      return this.dataURL === null;
    },
    sizeStyles() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    previewStyles() {
      return {
        ...this.sizeStyles,
        backgroundImage: this.dataURL ? `url(${this.dataURL})` : null,
      };
    },
  },
  methods: {
    async init() {
      const quality = await localforage.getItem(STORAGE_KEY);
      if (quality) {
        this.device.quality = quality;
      }

      const permission = await this.getPermissionState();
      if (permission === 'denied') {
        throw new Error('Não foi autorizado o acesso ao dispositivo de vídeo');
      } else if (permission !== 'granted') {
        // call to ask user permission
        await this.askUserPermission();
      }

      this.device.items = await listDevices('video');
      if (this.device.items.length > 0) {
        this.device.id = this.device.items[0].deviceId;
      } else {
        throw new Error('Nenhum dispositivo de vídeo foi encontrado');
      }

      this.video = this.$refs.video;
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext('2d');
      await this.openMedia();
    },
    onChangeQuality() {
      localforage.setItem(STORAGE_KEY, this.device.quality);
      this.updateDevice();
    },
    updateDevice() {
      this.closeMedia();
      this.openMedia();
    },
    async getPermissionState() {
      try {
        const permission = await navigator.permissions.query({ name: 'camera' });
        return permission ? permission.state : 'unknown';
      } catch (e) {
        return 'unknown';
      }
    },
    async askUserPermission() {
      const mediaStream = await getUserMedia();
      mediaStream.getVideoTracks()[0].stop();
    },
    async getMediaStream() {
      const constraints = {
        video: this.device.id ? { deviceId: this.device.id } : {},
        audio: false,
      };

      if (this.device.quality === 'auto') {
        const supports = getSupportedConstraints();
        if (supports.width) {
          constraints.video.width = { ideal: 1280, max: 1920 };
        }
        if (supports.height) {
          constraints.video.height = { ideal: 960 };
        }
      }

      if (Object.keys(constraints.video).length === 0) {
        constraints.video = true;
      }

      return getUserMedia(constraints);
    },
    async openMedia() {
      this.loading = true;

      try {
        this.mediaStream = await this.getMediaStream();
        this.imageCapture = new ImageCapture(this.mediaStream.getVideoTracks()[0]);
        this.video.srcObject = this.mediaStream;
        this.video.onloadedmetadata = () => {
          this.video.play();
          this.loading = false;
        };
      } catch (e) {
        this.errorMessage = e;
        this.loading = false;
      }
    },
    closeMedia() {
      if (this.video) {
        this.video.pause();
      }
      if (this.mediaStream) {
        this.mediaStream.getTracks()[0].stop();
      }
    },
    snapshot() {
      if (!this.hasVideo) {
        return Promise.resolve(null);
      }

      return new Promise((resolve) => {
        this.imageCapture.grabFrame()
          .then((imageBitmap) => {
            this.canvas.width = imageBitmap.width;
            this.canvas.height = imageBitmap.height;
            this.context.drawImage(imageBitmap, 0, 0);

            this.canvas.toBlob((blob) => {
              const dataURL = this.canvas.toDataURL(this.contentType);
              const extension = blob.type.replace('image/', '');
              const fileName = `captured-image.${extension}`;
              const file = new File([blob], fileName, { type: blob.type });

              if (!this.continuous) {
                this.dataURL = dataURL;
              }

              const data = { file, dataURL, extension };

              this.$emit('snapshot', data);

              resolve(data);
            }, this.contentType);

            if (!this.continuous) {
              this.closeMedia();
            }
          })
          .catch((e) => {
            this.$toast.error(e);
            resolve(null);
          });
      });
    },
    clear() {
      this.dataURL = null;
      this.$emit('snapshot', null);
      this.openMedia();
    },
  },
  beforeDestroy() {
    this.closeMedia();
  },
};
</script>

<style lang="scss">
@import '~assets/scss/variables';

.image-capture {
  position: relative;
  canvas {
    display: none;
  }
  video {
    cursor: pointer;
    display: block;
    &.no-video {
      background-color: $gray-color-light;
    }
  }
  .has-error {
    align-items: center;
    background-color: $gray-color-light;
    color: $error-color;
    display: flex;
    font-size: $font-size;
    justify-content: center;
    svg {
      font-size: $menu-icon-size;
    }
  }
  .capture-preview {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .device-settings {
    display: grid;
    direction: rtl;
    grid-template-columns: 1fr;
    grid-column-gap: $layout-spacing;
    left: 0;
    padding: $layout-spacing;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $zindex-2;
    .form-select {
      border-color: $gray-color;
    }
    &.device-settings-edit {
      direction: ltr;
      grid-template-columns: 2fr 1fr auto;
      background-color: rgba($light-color, .5);
    }
  }
}
</style>
